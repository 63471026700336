import React, { ReactNode } from 'react';
import Collapse from '@material-ui/core/Collapse';      
import { Alert, AlertTitle, AlertProps } from '@material-ui/lab';

interface ICustomAlertProps {
  severity: AlertProps['severity'];
  title?: string;
  isOpen?: boolean;
  children: ReactNode;
}

export const CustomAlert: React.FC<ICustomAlertProps> = ({severity, title, isOpen, children}) => {
  return(
    <Collapse in={isOpen}>
      <Alert severity={severity} variant="filled">
        <AlertTitle>{title}</AlertTitle>
        {children}
      </Alert>
    </Collapse>
  );
}
