import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {api} from '../model/api';
import { CONFIG } from '../model/config';
import {pdfjs, Document, Page, View} from 'react-pdf';
import QRCode from 'qrcode.react';
import CertificateDisplay from '../components/CertificateShare/page';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const getCertificateHash = async (userId: string, filename: string) =>
{
  console.log("sending certificate verification request");
  try {
    let response = await api.get(`certificate/verify/${userId}/${filename}`);
    let result = await response.json();
    return result;
  }
  catch (e)
  {
    console.log("problem with the request ", e.message);
    return false;
  }
}

const getCertificateData = async (userId: string, filename: string) =>
{
  console.log("sending certificate data request");
  try {
    let response = await api.get(`certificate/shared/${userId}/${filename}`);
    // let result = await response.json();
    console.log(response);
    return response;
  }
  catch (e)
  {
    console.log("problem with the request ", e.message);
    return undefined;
  }
}

export const CertificateVerification: React.FC<{}> = () => {

  const [certificateData, setCertificateData] = React.useState(null);
  const [certificateLink, setCertificateLink] = React.useState("");
  const [certificateVerified, setCertificateVerified] = React.useState(false);
  const [certificateIssuedAt, setCertificateIssuedAt] = React.useState("");
  const [userData, setUserData] = React.useState({userFirstName: "Unknown", userLastName: "Unknown"});
  const { userId, filename } = useParams<{userId:string, filename: string}>();

  const fetchCertificate = () => {
    async function fetch_data()
    {
      try{
        let data = await getCertificateHash(userId, filename);
        let certificate = await getCertificateData(userId, filename);
        console.log("response : ", data);
        if (data)
        {
          if (data.result != undefined)
          {
            setCertificateVerified(data.result);
            setCertificateIssuedAt(data.certificateIssuedAt);
          }
          setUserData(data);
        }
        if (certificate)
          setCertificateLink(certificate.url);
        setCertificateData(data);
      }
      catch (e)
      {
        return (e.message);
      }
    }
    fetch_data();
  };

  useEffect(fetchCertificate, []);
  const textStyle = {
    pending:{
      color: "#808080"
    },
    verified:{
      color: "#1da1f2"
    },
    notVerified:{
      color: "#F44336"
    }
  };

  const pdfStyle = {
    textAlign: "center",
    width: "100%",
    margin: "auto"
  };

  const containerStyle = {
    margin: "auto"
  };

  const canvasRef = React.createRef();

  return (
    certificateData &&
    <CertificateDisplay verified={certificateVerified} fullName={userData.userFirstName + " " + userData.userLastName}
      qrCodeLink={window.location.href} issueDate={certificateIssuedAt} pdfFile={certificateLink}/>
    // <div style={{textAlign: "center"}}>
    //   {(certificateData && certificateVerified) && <div style={{marginTop: "40px", display: "inline-flex"}}><div style={{width: "50%"}}><h2 style={textStyle.verified}>Authenticity verified by Decenture Blockchain <img src="/verified.svg" height="20px"/></h2></div>
    //     <div style={{width: "50%"}}><QRCode fgColor={"#1da1f2"}
    //     value={window.location.href}/></div>
    //   </div>}
    //   {(certificateData && !certificateVerified) && <div style={{marginTop: "40px", display: "inline-flex"}}><div style={{width: "50%"}}><h2 style={textStyle.notVerified}>Certificate is not authentic, verified by Decenture Blockchain</h2></div>
    //     <div style={{width: "50%"}}><QRCode fgColor={"#F44336"}
    //     value={window.location.href}/></div>
    //   </div>}
    //   {!certificateData && <div style={{marginTop: "40px", display: "inline-flex"}}><div style={{width: "50%"}}><h2 style={textStyle.pending}>Requesting verification from decenture blockchain. . .</h2></div>
    //     <div style={{width: "50%"}}><QRCode fgColor={"#808080"}
    //     value={window.location.href}/></div>
    //   </div>}
    //   {/* <h2>FirstName : {userData.userFirstName}</h2>
    //   <h2>LastName : {userData.userLastName}</h2> */}
    //   <div style={containerStyle}>
    //     <Document
    //     style={pdfStyle}
    //       file={certificateLink}
    //       >
    //       <Page className="certificateCanvas" style={pdfStyle} pageNumber={1}>
    //       </Page>
    //     </Document>
    //   </div>
    // </div>
  );
};
