import React, { useContext } from 'react';
import { Layout } from 'src/components/Layout';
import { AuthContext } from 'src/model/AuthContext';
import { DomoEmbed } from 'src/components/DomoEmbed';
import { CONFIG } from 'src/model/config';

export const SubjectsPage: React.FC<{}> = () => {
  const role = useContext(AuthContext).user.role;

  return (
    <Layout>
      
      {/* <h1>Subjects</h1> */}
      {role === 'student' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/studentSubjects`} />
      )}
      {role === 'teacher' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/teacherSubjects`} />
      )}
      {role === 'admin' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/adminSubjects`} />
      )}
    </Layout>
  );
};
