import ky from 'ky';
import storage from 'src/model/storage';
import { CONFIG } from './config';

export const api = ky.extend({
  prefixUrl: CONFIG.API_URL,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = storage.getItem(CONFIG.AUTH_TOKEN_KEY) || '';
        request.headers.set('Authorization', `Bearer ${token}`);
        request.headers.set('Content-Type', 'application/json');
      },
    ],
  },
});
