import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { LessonsPage } from 'src/pages/LessonsPage';
import { LoginPage } from 'src/pages/LoginPage';
import { PasswordResetPage } from 'src/pages/PasswordResetPage';
import { PasswordCodePage } from 'src/pages/PasswordCodePage';
import { PasswordEnterPage } from 'src/pages/PasswordEnterPage';
import { ActivateAccountPage } from 'src/pages/ActivateAccountPage';
import { RoomsPage } from 'src/pages/RoomsPage';
import { SettingsPage } from 'src/pages/SettingsPage';
import { StudentsPage } from 'src/pages/StudentsPage';
import { SubjectsPage } from 'src/pages/SubjectsPage';
import { TeachersPage } from 'src/pages/TeachersPage';
import { UploadPage } from 'src/pages/UploadPage';
import { MuiThemeProvider } from '@material-ui/core';
import { DecentureTheme } from 'src/assets/theme';
import { AuthContext } from 'src/model/AuthContext';
import { AuthModel } from "src/model/AuthModel";
import { AuthService } from "src/model/AuthService";
import { NotFound } from 'src/pages/NotFound';
import { PrivateRoute } from 'src/app/PrivateRoute';
import { OverviewPage } from 'src/pages/OverviewPage';
import { SchedulePage } from 'src/pages/SchedulePage';
import { BlockPage } from 'src/pages/BlockchainPage';
import { CertificateVerification } from 'src/pages/CertificateShare';
import { Layout } from 'src/components/Layout';
import { OneViewPage } from 'src/pages/OneViewPage';

export function App() {
  return (
    <AuthContext.Provider value={new AuthModel(new AuthService())}>
      <MuiThemeProvider theme={DecentureTheme}>
        <div className='App'>
          <BrowserRouter>
            <Switch>
              <Route path='/login' exact component={LoginPage} />
              <Route path='/passwordreset'   exact component={PasswordResetPage} />
              <Route path='/passwordcode'    exact component={PasswordCodePage} />
              <Route path='/passwordenter'   exact component={PasswordEnterPage} />
              <Route path='/activate'        exact component={ActivateAccountPage} />
              <Route path='/certificate-share/:userId/:filename/view' exact component={CertificateVerification} />
              <PrivateRoute path='/settings' exact component={SettingsPage} role='student|teacher|admin|super-admin' />
              <PrivateRoute path='/'         exact component={OverviewPage} role='student|teacher|admin|super-admin' />
              <PrivateRoute path='/schedule' exact component={SchedulePage} role='        teacher|admin|super-admin' />
              <PrivateRoute path='/subjects' exact component={SubjectsPage} role='student|teacher|admin|super-admin' />
              <PrivateRoute path='/lessons'  exact component={LessonsPage}  role='        teacher|admin|super-admin' />
              <PrivateRoute path='/students' exact component={StudentsPage} role='        teacher|admin|super-admin' />
              <PrivateRoute path='/teachers' exact component={TeachersPage} role='                admin|super-admin' />
              <PrivateRoute path='/rooms'    exact component={RoomsPage}    role='                admin|super-admin' />
              <PrivateRoute path='/upload'   exact component={UploadPage}   role='                admin|super-admin' />
              <PrivateRoute path='/blockchain' exact component={BlockPage}  role='                admin|super-admin' />
              <PrivateRoute path='/OneView' exact component={OneViewPage}  role='                      super-admin' />
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </div>
      </MuiThemeProvider>
    </AuthContext.Provider>
  );
}
