const LOCAL_CONFIG = {
  API_URL: 'https://stem.decenture.net:3000/api/v1',
  UPLOAD_URL: '/import',
  AUTH_TOKEN_KEY: 'YPyDNUzKjhCMgmQF',
  IMAGE_BACKGROUND: 'default_background.jpg',
  IMAGE_LOGO: '/default_logo.png',
  UNIVERSITY_NAME: 'Decenture (default)',
};

export const CONFIG = {
  ...LOCAL_CONFIG,

  // overwrite local config
  // @ts-ignore
  ...window.config,

  TEMP_TOKEN: process.env.TEMP_TOKEN,
};
