import storage from './storage';
import { CONFIG } from './config';
import { inject } from 'inversify';
import { AuthService } from './AuthService';
import { LoginUserDto } from '../../../shared/src/dto/module/auth/loginUser.dto';
import { UserRole } from '../../../shared/src/model/role';
import { decode } from 'jws';

type AuthenticatedUserInfo = {
  userId: string;
  email: string;
  role: UserRole;
};

export class AuthModel {
  isLoading: boolean;

  constructor(@inject(AuthService) private readonly authService: AuthService) {}

  get accessToken(): null | string {
    return storage.getItem(CONFIG.AUTH_TOKEN_KEY);
  }

  get isAuthenticated(): boolean {
    return this.accessToken ? true : false;
  }

  get user(): AuthenticatedUserInfo {
    const decodedToken = decode(this.accessToken);
    return {
      userId: decodedToken.payload.userId,
      email: decodedToken.payload.email,
      role: decodedToken.payload.role,
    };
  }

  isRole(role?: UserRole): boolean {
    return this.isAuthenticated && (!role || this.user.role === role);
  }

  isAnyOfRoles(roles: UserRole[]): boolean {
    return this.isAuthenticated && roles.includes(this.user.role);
  }

  async signIn(request: LoginUserDto, errorCb: Function) {
    try {
      this.isLoading = true;
      const { accessToken } = await this.authService.signIn(request);
      storage.setItem(CONFIG.AUTH_TOKEN_KEY, accessToken);
    } catch (e) {
      errorCb(e)
    } finally {
      this.isLoading = false;
    }
  }

  async signOut() {
    storage.removeItem(CONFIG.AUTH_TOKEN_KEY);
  }
}
