import React, { useContext, FormEvent } from 'react';
import { Layout } from 'src/components/Layout';
import TextField from '@material-ui/core/TextField';
import { AuthContext } from 'src/model/AuthContext';
import { ResetPasswordContext } from 'src/model/ResetPasswordContext';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CustomAlert } from 'src/components/CustomAlert';

export const SettingsPage: React.FC<{}> = () => {
  const authModel = useContext(AuthContext);
  const resetPasswordModel = useContext(ResetPasswordContext);
  const history = useHistory();
  const [isOldPasswordWrong, setOldPasswordWrong] = React.useState(false);
  const [isPasswordMismatch, setPasswordMismatch] = React.useState(false);
  const [isPasswordBad, setPasswordBad] = React.useState(false);
  
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const oldPassword = e.target['oldPassword'].value;
    const newPassword = e.target['newPassword'].value;
    const repeat = e.target['repeat'].value;

    if (newPassword.length < 6) {
      setPasswordBad(true);
      return;
    } else {
      setPasswordBad(false);
    }

    if (newPassword !== repeat) {
      setPasswordMismatch(true);
      return;
    } else {
      setPasswordMismatch(false);
    }
    
    await resetPasswordModel.handleChangePassword(
      {
        oldPassword,
        newPassword
      },
      () => {
        // TODO: Notify user
        authModel.signOut();
        history.push("/login");
      },
      () => {
        setOldPasswordWrong(true);
      }
    );    
  };

  return (
    <Layout>
      <h1>Settings</h1>

      <form className='loginForm' noValidate autoComplete='off' onSubmit={handleSubmit}>
        <div>
          <TextField fullWidth id='email' label='Email' value={authModel.user.email} disabled />
        </div>
        <div>
          <TextField fullWidth id='oldPassword' label='Old Password' type='password' onInput={() => setOldPasswordWrong(false)} />
          <CustomAlert isOpen={isOldPasswordWrong} severity='error' title="Can't change password">
            Please check your old password.
          </CustomAlert>
        </div>
        <div>
          <TextField fullWidth id='newPassword' label='New Password' type='password' onInput={() => setPasswordBad(false)} />
          <CustomAlert isOpen={isPasswordBad} severity='error' title="Weak password">
            Please make sure your passwords is at least 6 symbols long.
          </CustomAlert>
        </div>
        <div>
          <TextField fullWidth id='repeat' label='Repeat New Password' type='password' onInput={() => setPasswordMismatch(false)} />
            <CustomAlert isOpen={isPasswordMismatch} severity='error' title="Password mismatch">
            Please make sure your passwords match.
          </CustomAlert>
        </div>
        <div style={{marginTop: '6ex'}}>
          <Button fullWidth variant='contained' color='primary' type='submit'>
            Change Password
          </Button>
        </div>
      </form>
    </Layout>
  );
};
