import React, { useContext } from 'react';
import { Layout } from 'src/components/Layout';
import { AuthContext } from 'src/model/AuthContext';
import { DomoEmbed } from 'src/components/DomoEmbed';
import { CONFIG } from 'src/model/config';

export const LessonsPage: React.FC<{}> = () => {
  const role = useContext(AuthContext).user.role;

  return (
    <Layout>
      <h1>Lessons</h1>
      {role === 'student' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/studentLessons`} />
      )}
      {role === 'teacher' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/teacherLessons`} />
      )}
      {role === 'admin' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/adminLessons`} />
      )}      
    </Layout>
  );
};
