import React, { useState, useEffect } from 'react';
import {api} from '../model/api';
import  Card  from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';



// const getCertificateHash = async (userId: string, filename: string) =>
// {
//   console.log("sending certificate verification request");
//   try {
//     let response = await api.get(`certificate/verify/${userId}/${filename}`);
//     let result = await response.json();
//     return result;
//   }
//   catch (e)
//   {
//     console.log("problem with the request ", e.message);
//     return false;
//   }
// }

// const getCertificateData = async (userId: string, filename: string) =>
// {
//   console.log("sending certificate data request");
//   try {
//     let response = await api.get(`certificate/shared/${userId}/${filename}`);
//     // let result = await response.json();
//     console.log(response);
//     return response;
//   }
//   catch (e)
//   {
//     console.log("problem with the request ", e.message);
//     return undefined;
//   }
// }

const getAllData = async () =>{
  try{
    let response = await api.get('debug/status');
    console.log("data arrived");
    return await response.json();
  }
  catch (e)
  {
    console.log("problem with the request ", e.message);
    return undefined;
  }
}

export const BlockPage: React.FC<{}> = () => {

  // const [certificateData, setCertificateData] = React.useState(null);
  // const [certificateLink, setCertificateLink] = React.useState("");
  // const [certificateVerified, setCertificateVerified] = React.useState(false);
  // const [userData, setUserData] = React.useState({userFirstName: "Unknown", userLastName: "Unknown"});
  // const { userId, filename } = useParams<{userId:string, filename: string}>();
  const [allData, setAllData] = React.useState<Array<any>>([]);
  const [received, setReceived] = React.useState(false);

  const fetchData = () => {
    async function fetch_data()
    {
      try{
        let data = await getAllData();
        if (data)
        {
          console.log(`data is ${data}`);
          setReceived(true);
          setAllData(data);
        }
        // let data = await getCertificateHash(userId, filename);
        // let certificate = await getCertificateData(userId, filename);
        // console.log("response : ", data);
        // if (data)
        // {
        //   if (data.result != undefined)
        //     setCertificateVerified(data.result);
        //   setUserData(data);
        // }
        // if (certificate)
        //   setCertificateLink(certificate.url);
        // setCertificateData(data);
      }
      catch (e)
      {
        return (e.message);
      }
    }
    fetch_data();
  };

  const cardStyle={
    regular:
    {
      width: "25%",
      margin: "20px",
    }
  }

  useEffect(fetchData, []);

  let mycards:Array<any> = [];
  for (const entry of allData)
  {
    if (entry.type == "certificate")
    {
      mycards.push(<Card style={cardStyle.regular}>
          <CardContent>
            <h3>{entry.type}</h3>
            <p>id : {entry.data.id}</p>
            <p>filename : {entry.data.filename}</p>
          </CardContent>
        </Card>);
    }
    else if (entry.type == "attendance")
    {
      mycards.push(<Card style={cardStyle.regular}>
        <CardContent>
          <h3>{entry.type}</h3>
          <p>id : {entry.data.id}</p>
          <p>trackingCodeId : {entry.data.trackingCodeId}</p>
        </CardContent>
      </Card>);
    }
  }
  return (
    <div style={{textAlign: "center"}}>
      {mycards}
    </div>
  );
};
