import React, { useState } from 'react';
import {DropzoneArea} from 'material-ui-dropzone'
import { Button, TextField } from '@material-ui/core';
import { fetchAuth } from 'src/model/fetchAuth';

interface IUploadCertificateControlProps {
  url: string,
  onUploadDone?: Function,
}

type UploadFile = Blob;

export const UploadCertificate: React.FC<IUploadCertificateControlProps> = ({ url, onUploadDone }) => {
  const [files, setFiles] = useState([] as Array<UploadFile>);
  const [refUserId, setRefUserId] = useState('');
  const [filesKey, setFilesKey] = useState(0);
  const [status, setStatus] = useState('Upload');
  const [deleteStatus, setDeleteStatus] = useState('Delete');
  const [uploadResult, setUploadResult] = useState('');
  const [deleteResult, setDeleteResult] = useState('');

  const handleChange = (files: Array<UploadFile>) => {
    setFiles(files);
  }

  const cleanUp = () => {
    setFiles([]);

    // https://github.com/react-dropzone/react-dropzone/issues/881
    setFilesKey(filesKey+1);
  }

  const handleDelete = () => {
    setDeleteStatus('Deleting...');

    fetchAuth(url + '-delete', {
        method: 'DELETE',
        body: JSON.stringify({refUserId})
      }, {
          'Content-Type': 'application/json; charset=utf-8'
      })
      .then(
        response => {
          setDeleteStatus('Delete');
          cleanUp();
          return response.json();
        },
        error => {
          console.error(error);
        }
      )
      .then((response) => {
        console.log('Deleted', response.deleteResult);
        if (response.deleteResult) {
          setDeleteResult(`${response.deleteResult} files deleted`);
        } else {
          setDeleteResult(`delete failed`);
        }
      })
  }

  const handleUpload = () => {
    setStatus('Uploading...');
    var formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }
    
    formData.append('refUserId', refUserId);

    fetchAuth(url, {
        method: 'POST',
        body: formData
      })
      .then(
        response => {
          setStatus('Upload');
          cleanUp();
          if (typeof onUploadDone === 'function') {
            onUploadDone(response);
          }
          return response.json();
        },
        error => {
          console.error(error);
        }
        )
      .then((response) => {
        console.log('Uploaded', response.importResult);
        if (response.importResult) {
          let keys = Object.keys(response.importResult)
          setUploadResult(`${keys.length} files uploaded`);
        } else {
          setUploadResult(`upload failed`);
        }
      })
  }

  return (
    <table className='uploadTable'>
      <tbody>
        <tr>
          <td><h4>Certificate</h4></td>
          <td className='inputzone' >
            <TextField id='refUserId' label='User Ref Id' fullWidth value={refUserId} onChange={e => setRefUserId(e.target.value)} />
          </td>
          <td className='dropzone multiple' key={filesKey}>
            <DropzoneArea 
              filesLimit={100}
              dropzoneText='Drag and drop a PDF file or click here'
              onChange={handleChange}
              acceptedFiles={['application/pdf']}
              showFileNames={true}
            />
          </td>
          <td>
            <Button variant='contained' color='primary' disabled={!files.length || status !== 'Upload'} onClick={handleUpload}>{status}</Button>
            <small className='uploadResult'>{uploadResult}</small>
          </td>
          <td className='hint'>
            <Button variant='contained' color='primary' disabled={!refUserId} onClick={handleDelete}>{deleteStatus}</Button>
            <small className='uploadResult'>{deleteResult}</small>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
