import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'src/model/AuthContext';
import { useHistory } from 'react-router-dom';

interface IDomoEmbedProps {
  url: string;
}

export const DomoEmbed: React.FC<IDomoEmbedProps> = ({ url }) => {
  const [content, setContent] = useState('');
  const authModel = useContext(AuthContext);
  const iframe = useRef<HTMLIFrameElement>(null);
  const history = useHistory();

  useEffect(() => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200 && iframe.current) {
          // iframe.current.src = URL.createObjectURL(xhr.response);
          setContent(xhr.response);
        }
        if (xhr.status === 401) {
          authModel.signOut();
          history.push("/login");
        }
      }
    };
    xhr.open('GET', url);
    xhr.setRequestHeader('Authorization', 'Bearer ' + authModel.accessToken);
    xhr.send();
  }, [])

  return (
    <iframe sandbox="allow-forms allow-scripts allow-same-origin" title='Domo charts' ref={iframe} className='domoEmbedIframe' srcDoc={content}/>
  );
};
