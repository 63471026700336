import React, { useContext, FormEvent } from 'react';
import { Card, TextField, Button } from '@material-ui/core';
import { MainLogo } from 'src/components/MainLogo';
import { NavLink, useHistory } from 'react-router-dom';
import { NotLoggedInLayout } from 'src/components/NotLoggedInLayout';
import { ResetPasswordContext } from 'src/model/ResetPasswordContext';
import { CustomAlert } from 'src/components/CustomAlert';

export const PasswordEnterPage: React.FC<{}> = () => {
  const resetPasswordModel = useContext(ResetPasswordContext);
  const history = useHistory();
  const codeFromLink = window.location.search.replace('?code=', '') || '';
  const [isErrorOn, setErrorOn] = React.useState(false);
  const [isPasswordMismatch, setPasswordMismatch] = React.useState(false);
  const [isPasswordBad, setPasswordBad] = React.useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const code = e.target['code'].value;
    const password = e.target['password'].value;
    const repeat = e.target['repeat'].value;

    if (password.length < 6) {
      setPasswordBad(true);
      return;
    } else {
      setPasswordBad(false);
    }

    if (password !== repeat) {
      setPasswordMismatch(true);
      return;
    } else {
      setPasswordMismatch(false);
    }
    
    resetPasswordModel.handleNewPassword(
      code,
      password,
      () => history.push("/login"),
      () => setErrorOn(true)
    );
  };

  const noAutocompleteProps = {
    autocomplete: 'new-password',
  }

  return (
    <NotLoggedInLayout>
      <Card style={{ padding: '38px' }}>
        <MainLogo />
        <form className='loginForm' noValidate autoComplete='off' onSubmit={handleSubmit}>
          <h3>Password reset</h3>
          <div>Please check your inbox and click the link sent to you or enter the security code manually</div>
          <div>
            <TextField fullWidth id='code' inputProps={noAutocompleteProps} label='Security code (from e-mail)' defaultValue={codeFromLink} onInput={() => setErrorOn(false)} disabled={codeFromLink!=''}/>
            <CustomAlert isOpen={isErrorOn} severity='error' title="Can't change password">
              Please check your security code!
            </CustomAlert>
          </div>
          <div>
            <TextField fullWidth id='password' inputProps={noAutocompleteProps} type='password' label='New password' onInput={() => setPasswordBad(false)} />
            <CustomAlert isOpen={isPasswordBad} severity='error' title="Weak password">
              Please make sure your passwords is at least 6 symbols long.
            </CustomAlert>
          </div>
          <div>
            <TextField fullWidth id='repeat' type='password' inputProps={noAutocompleteProps} label='Repeat new password' onInput={() => setPasswordMismatch(false)} />
            <CustomAlert isOpen={isPasswordMismatch} severity='error' title="Password mismatch">
              Please make sure your passwords match.
            </CustomAlert>
          </div>
          <div className='buttons'>
            <div>
              <Button fullWidth variant='contained' color='primary' type='submit'>
                Set new password and log in
              </Button>
            </div>
            <div>
              <Button fullWidth variant='outlined' color='primary' component={NavLink} exact to='/login' >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </NotLoggedInLayout>
  );
};
