import { inject } from 'inversify';
import { AuthService } from './AuthService';
import { CreateChangePasswordTokenDto } from '../../../shared/src/dto/module/user/createChangePasswordToken.dto';
import { ChangeUserPasswordByTokenDto } from '../../../shared/src/dto/module/user/changeUserPasswordByToken.dto';
import { ChangeUserPasswordDto } from '../../../shared/src/dto/module/user/changeUserPassword.dto';
import { ActivateUserDto } from '../../../shared/src/dto/module/user/activateUser.dto';

export class ResetPasswordModel {
  isLoading: boolean = false;
  confirmationCode: string = '';

  constructor(
    @inject(AuthService) private readonly authService: AuthService,
  ) {}

  handleConfirmationCodeInput(value) {
    this.confirmationCode = value;
  }

  async handleCreateChangePasswordToken(request: CreateChangePasswordTokenDto, navigateCb: () => any, errorCb: (e: Error) => any) {
    try {
      this.isLoading = true;
      await this.authService.createChangePasswordToken(request);
      navigateCb();
    } catch (e) {
      errorCb(e);
    } finally {
      this.isLoading = false;
    }
  }

  async handleNewPassword(confirmationToken: string, newPassword: string, navigateCb: () => any, errorCb: (e: Error) => any) {
    const request: ChangeUserPasswordByTokenDto = {
      newPassword,
      confirmationToken: confirmationToken
    }

    try {
      this.isLoading = true;
      await this.authService.changePasswordByToken(request);
      navigateCb();
    } catch (e) {
      errorCb(e);
    } finally {
      this.isLoading = false;
    }
  }

  async handleActivateUser(token: string, password: string, navigateCb: () => any, errorCb: (e: any) => any) {
    const request: ActivateUserDto = {
      password,
      token
    }

    try {
      this.isLoading = true;
      let result = await this.authService.activateUser(request);
      if (result.status != 201)
        throw result;
      navigateCb();
    } catch (e) {
      let decodedError = "Error";
      if (e.response)
      {
        decodedError = await e.response.json();
      }
      errorCb(decodedError);
    } finally {
      this.isLoading = false;
    }
  }

  async handleChangePassword(request: ChangeUserPasswordDto, resetForm: () => void, errorCb: (e: Error) => any) {
    try {
      this.isLoading = true;
      await this.authService.changePassword(request);
      resetForm();
    } catch (e) {
      errorCb(e);
    } finally {
      this.isLoading = false;
    }
  }
}
