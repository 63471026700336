import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Menu, MenuItem, useMediaQuery} from '@material-ui/core';
import { AuthContext } from 'src/model/AuthContext';
import { TriangleDown, UserIcon } from 'src/assets/icons';

export const UserMenu: React.FC<{}> = () => {
  const history = useHistory();
  const authModel = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    handleClose();
    history.push("/settings");
  };

  const handleLogout = () => {
    handleClose();
    authModel.signOut();
    history.push("/login");
  };

  const icon = useMediaQuery('(max-width: 800px)');

  return (
    <>
      <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleClick} endIcon={<TriangleDown/>} >
        {icon && <UserIcon/>}
        {!icon && authModel.user.email}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSettings}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
