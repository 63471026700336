import React, { useState, useEffect } from 'react';
import { Layout } from 'src/components/Layout';
import { fetchAuth } from 'src/model/fetchAuth';
import { TeacherCourseSchedule } from '../../../shared/src/model/teacherCourseSchedule';
import { TrackingCode } from '../../../shared/src/model/trackingCode';
import { CONFIG } from 'src/model/config';
import { Modal, Select, MenuItem } from '@material-ui/core';
import { QRCode } from 'src/util/qrcode';

export const SchedulePage: React.FC<{}> = () => {
  const [currentItem, setCurrentItem] = useState<TeacherCourseSchedule>();
  const [schedule, setSchedule] = useState<TeacherCourseSchedule[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);


  // Fetch range from UI

  const DAY = 86400000;
  const WEEK = DAY * 7;
  const MONTH = DAY * 31;
  const YEAR = DAY * 366;
  const BEGINNING_OF_TODAY = Math.floor(Date.now() / DAY) * DAY;

  const [selectedRange, setSelectedRange] = useState<number>(DAY);

  const request = {
    fromDate: new Date(BEGINNING_OF_TODAY).toISOString(),
    toDate: new Date(BEGINNING_OF_TODAY + selectedRange).toISOString()
  };


  // Fetch data from server

  const fetchSchedule = () => {
    fetchAuth(
      `${CONFIG.API_URL}/courseSchedule/teacher?fromDate=${request.fromDate}&toDate=${request.toDate}`
    ).then(
        res => res.json()
      )
      .then(
        result => setSchedule(result),
        error => console.error(error)
      )
  }

  useEffect(fetchSchedule, [selectedRange]);


  // Draw QR in popup
  const showQR = (item: TeacherCourseSchedule) => {
    setCurrentItem(item);
    setIsModalOpen(true);
  }

  const drawQR = () => {
    const qrCodesContainer = document.getElementById('qrCodesContainer');
    if (qrCodesContainer && currentItem) {
      currentItem.trackingCodes.forEach((code: TrackingCode) => {
        let qrItemWrapper = document.createElement('div');
        new QRCode(qrItemWrapper, code.id);
        qrCodesContainer.appendChild(qrItemWrapper);
      })
    }
  }


  // Date-time utils
  const formatDate = (rawDate: string): string => new Date(rawDate).toLocaleDateString('en-CA');
  const formatTime = (rawDate: string): string => new Date(rawDate).toLocaleTimeString().replace(':00 ', ' ');

  var prev: TeacherCourseSchedule;
  const isNextDay = (item: TeacherCourseSchedule): boolean => {
    const thisDay = new Date(item.startAt).getDay();
    const prevDay = prev && new Date(prev.startAt).getDay();
    prev = item;
    return (thisDay !== prevDay);
  }


  // render()
  return (
    <Layout>
      
      <h1>
        Your Schedule&nbsp;
        <Select
          style={{font: 'inherit'}}
          value={selectedRange}
          onChange={(e) => setSelectedRange(parseInt(e.target.value as string))}
        >
          <MenuItem value={DAY}>for today</MenuItem>
          <MenuItem value={WEEK}>for a next week</MenuItem>
          <MenuItem value={MONTH}>for a next month</MenuItem>
          <MenuItem value={YEAR}>for a next year</MenuItem>
        </Select>
      </h1>
      
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onRendered={() => drawQR()}
      >
        <div className='qrModal'>
          { currentItem &&
            <h3>
              <span className='course'>{currentItem.course.name}</span>
              <span className='classroom'>{currentItem.classroom.number}, {currentItem.classroom.name}</span>
              <span className='time'>{formatTime(currentItem.startAt)} - {formatTime(currentItem.endAt)}</span>
            </h3>
          }
          <div id='qrCodesContainer'>

          </div>
        </div>
      </Modal>

      <div className='scheduleList'>
        {schedule.map((item: TeacherCourseSchedule, key) =>
          <React.Fragment key={key}>
            { isNextDay(item) && 
              <h3>{formatDate(item.startAt)}</h3>
            }
            <p className={'scheduleItem clickable' + (Date.parse(item.endAt) < Date.now() ? ' past' : '')} onClick={() => showQR(item)}>
              <span className='course'>{item.course.name}</span>
              <span className='classroom'>{item.classroom.number}, {item.classroom.name}</span>
              <span className='time'>{formatTime(item.startAt)} - {formatTime(item.endAt)}</span>
            </p>
          </React.Fragment>
        )}
      </div>
    
    </Layout>
  );
};
