import { action } from 'mobx';
import { LoginUserDto } from '../../../shared/src/dto/module/auth/loginUser.dto';
import { api } from './api';
import { CreateChangePasswordTokenDto } from '../../../shared/src/dto/module/user/createChangePasswordToken.dto';
import { ChangeUserPasswordDto } from '../../../shared/src/dto/module/user/changeUserPassword.dto';
import { ChangeUserPasswordByTokenDto } from '../../../shared/src/dto/module/user/changeUserPasswordByToken.dto';
import { ActivateUserDto } from '../../../shared/src/dto/module/user/activateUser.dto';

export class AuthService {

  @action
  async signIn(request: LoginUserDto): Promise<{ accessToken: string }> {
    let response = await api
      .post(
        'auth',
        {
          body: JSON.stringify(request),
        },
      );
      console.log(response);
    return await response.json();
  }

  async createChangePasswordToken(request: CreateChangePasswordTokenDto) {
    return await api
      .post(
        'user/changePasswordToken',
        {
          body: JSON.stringify(request),
        },
      )
      .json();
  }

  async changePasswordByToken(request: ChangeUserPasswordByTokenDto) {
    return await api
      .patch(
        'user/changePasswordByToken',
        {
          body: JSON.stringify(request),
        },
      )
      .json();
  }

  async changePassword(request: ChangeUserPasswordDto) {
    return await api
      .patch(
        'user/changePassword',
        {
          body: JSON.stringify(request),
        },
      )
      .json();
  }

  async activateUser(request: ActivateUserDto) {
    return await api
      .post(
        'user/activate',
        {
          body: JSON.stringify(request),
        },
      );
  }
}
