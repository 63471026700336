import React from 'react';
import { NavLink } from 'react-router-dom';
import { NotLoggedInLayout } from 'src/components/NotLoggedInLayout';

export const NotFound: React.FC<{}> = () => {
  return (
    <NotLoggedInLayout>
      <h1 className='notFound'><NavLink exact to='/'>404</NavLink></h1>
    </NotLoggedInLayout>
  );
};
