import React, { useContext } from 'react';
import { Layout } from 'src/components/Layout';
import { DomoEmbed } from 'src/components/DomoEmbed';
import { CONFIG } from 'src/model/config';
import { AuthContext } from 'src/model/AuthContext';

export const OverviewPage: React.FC<{}> = () => {
  const role = useContext(AuthContext).user.role;

  return (
    <Layout>
      
      {/* <h1>Overview <small hidden>({role})</small></h1> */}
      {role === 'student' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/studentOverview`} />
      )}
      {role === 'teacher' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/teacherOverview`} />
      )}
      {role === 'admin' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/adminOverview`} />
      )}
    </Layout>
  );
};
