
import "./page.css";
import PdfPage from './pdf';
import Sidebar from './sidebar';
import MobileTop from "./mobiletTop";
import MobileBottom from "./mobileBotom";
import Share from './share';
import React, {useState} from 'react';

function Page({verified,fullName,qrCodeLink,issueDate,pdfFile}) {
  const [show,setShow] = useState(false);
  return (
    <>
          <div id="share"></div>
          <div className="Page">
              <Share show={show} onClose={setShow} qrCodeLink={qrCodeLink} fullName={fullName}></Share>
              <MobileTop fullName={fullName} verified={verified}></MobileTop>
              <Sidebar verified = {verified} fullName = {fullName} qrCodeLink = {qrCodeLink} issueDate = {issueDate} pdfFile = {pdfFile} show={show} setShow={setShow}></Sidebar>
              <PdfPage verified = {verified} pdfFile = {pdfFile}></PdfPage>
              <MobileBottom verified={verified} qrCodeLink={qrCodeLink} issueDate={issueDate} pdfFile={pdfFile}  setShow={setShow}></MobileBottom>
          </div>
  </>
);
}

export default Page;