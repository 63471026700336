import React, { useContext } from 'react';
import { Layout } from 'src/components/Layout';
import { AuthContext } from 'src/model/AuthContext';
import { DomoEmbed } from 'src/components/DomoEmbed';
import { CONFIG } from 'src/model/config';

export const TeachersPage: React.FC<{}> = () => {
  const role = useContext(AuthContext).user.role;

  return (
    <Layout>
      
      {/* <h1>Teachers</h1> */}
      {role === 'student' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/studentTeachers`} />
      )}
      {role === 'teacher' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/teacherTeachers`} />
      )}
      {role === 'admin' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/adminTeachers`} />
      )}
    </Layout>
  );
};
