import React, { useContext, FormEvent } from 'react';
import { useHistory } from "react-router-dom";
import { Card, TextField, Button } from '@material-ui/core';
import { MainLogo } from 'src/components/MainLogo';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'src/model/AuthContext';
import { NotLoggedInLayout } from 'src/components/NotLoggedInLayout';
import { CustomAlert } from 'src/components/CustomAlert';

const ERRORS = {
  NONE: {
    title: "",
    message: "",
  },
  WRONG_CREDENTIALS: {
    title: "Can't login",
    message: "Please check the e-mail address and the password.",
  },
  SERVER_NOT_AVAILABLE: {
    title: "Server is not available",
    message: "We are working on it. Please try later.",
  }
}

export const LoginPage: React.FC<{}> = () => {
  const authModel = useContext(AuthContext);
  const history = useHistory();
  const [errorAlert, setErrorAlert] = React.useState(ERRORS.NONE);

  const signIn = async (email: string, password: string) => {
    await authModel.signIn(
      {
        email,
        password
      },
      (err: Error) => {
        console.error("[LoginPage]", err);
        if (err.message.includes("Failed to fetch")) {
          setErrorAlert(ERRORS.SERVER_NOT_AVAILABLE);
        } else {
          setErrorAlert(ERRORS.WRONG_CREDENTIALS);
        }
      }
    );

    if (authModel.isAuthenticated) {
      history.push("/");
    }
  };
  
  const handleSubmit = (e: FormEvent) => {
    signIn(e.target['email'].value, e.target['password'].value);
    e.preventDefault();
  };

  return (
    <NotLoggedInLayout>
      <Card style={{padding: '38px'}}>
        <MainLogo />
        <form className='loginForm' noValidate autoComplete='off' onSubmit={handleSubmit}>
          <div>
            <TextField fullWidth id='email' label='E-mail' onInput={() => setErrorAlert(ERRORS.NONE)} />
          </div>
          <div>
            <TextField fullWidth id='password' label='Password' type='password' onInput={() => setErrorAlert(ERRORS.NONE)} />
          </div>
          <CustomAlert isOpen={!!errorAlert.title} severity='error' title={errorAlert.title} >
            {errorAlert.message}
          </CustomAlert>
          <div className='buttons'>
            <div>
              <Button fullWidth variant='contained' color='primary' type='submit'>
                Login
              </Button>
            </div>
            <div>
              <NavLink className='gray' to='/passwordreset'>
                Forgot password?
              </NavLink>
            </div>
          </div>
        </form>
      </Card>
    </NotLoggedInLayout>
  );
};
