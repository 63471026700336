import React from 'react';

export const StudentsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 13.18V17.18L12 21L19 17.18V13.18L12 17L5 13.18ZM12 3L1 9L12 15L21 10.09V17H23V9L12 3Z" fill="#284D82" />
  </svg>
);

export const GroupsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z" fill="#284D82" />
  </svg>
);

export const SubjectsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z" fill="#284D82" />
  </svg>
);

export const LessonsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 4.5C15.55 4.5 13.45 4.9 12 6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6V20.65C1 21.3 1.73 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.66 21.26 23 20.86 23 20.6V6C21.51 4.88 19.37 4.5 17.5 4.5ZM21 18.5C19.9 18.15 18.7 18 17.5 18C15.8 18 13.35 18.65 12 19.5V8C13.35 7.15 15.8 6.5 17.5 6.5C18.7 6.5 19.9 6.65 21 7V18.5Z" fill="#284D82" />
  </svg>
);

export const RoomsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 6V21H3V19H5V3H14V4H19V19H21V21H17V6H14ZM10 11V13H12V11H10Z" fill="#284D82" />
  </svg>
);

export const UserIcon = () => (
  <img src="/user.png" width="24" height="24"/>
);

export const TeachersIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.125 16.125C8.36764 16.125 9.375 15.1176 9.375 13.875C9.375 12.6324 8.36764 11.625 7.125 11.625C5.88236 11.625 4.875 12.6324 4.875 13.875C4.875 15.1176 5.88236 16.125 7.125 16.125Z" fill="#284D82" />
    <path d="M9.1875 17.25H5.0625C3.92475 17.25 3 18.1747 3 19.3125V20.4375C3 20.748 3.252 21 3.5625 21H10.6875C10.998 21 11.25 20.748 11.25 20.4375V19.3125C11.25 18.1747 10.3253 17.25 9.1875 17.25Z" fill="#284D82" />
    <path d="M19.6875 3H8.0625C7.3425 3 6.75 3.5925 6.75 4.3125V10.5225C6.87 10.5075 6.9975 10.5 7.125 10.5C8.8575 10.5 10.29 11.8125 10.4775 13.5H12V15.9375C12 16.185 12.1575 16.4025 12.3975 16.4775C12.45 16.4925 12.51 16.5 12.5625 16.5C12.7425 16.5 12.9225 16.41 13.0275 16.2525L14.9175 13.5H19.6875C20.4075 13.5 21 12.9075 21 12.1875V4.3125C21 3.5925 20.4075 3 19.6875 3V3ZM18.6225 6.1725L15.81 9.5475C15.6075 9.7875 15.255 9.8175 15.015 9.6225L13.1475 8.0625L10.695 10.35C10.59 10.4475 10.4475 10.5 10.3125 10.5C10.1625 10.5 10.0125 10.44 9.9 10.32C9.69 10.095 9.705 9.735 9.93 9.525L12.7425 6.9C12.945 6.705 13.2675 6.6975 13.485 6.8775L15.3 8.3925L17.7525 5.4525C17.955 5.2125 18.3075 5.1825 18.5475 5.3775C18.7875 5.58 18.8175 5.9325 18.6225 6.1725Z" fill="#284D82" />
  </svg>
);

export const UploadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M12 8.5L8 12.5L11 12.5L11 21.5L13 21.5L13 12.5L16 12.5L12 8.5ZM3 21.5L9 21.5L9 19.51L3 19.51L3 5.48L21 5.48L21 19.51L15 19.51L15 21.5L21 21.5C22.1 21.5 23 20.6 23 19.5L23 5.5C23 4.4 22.1 3.5 21 3.5L3 3.5C1.9 3.5 0.999999 4.4 0.999999 5.5L1 19.5C1 20.6 1.9 21.5 3 21.5Z" fill="#284D82" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TriangleDown = () => (
  <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0L5 5L10 0L0 0Z" fill="black" fillOpacity="0.54" />
  </svg>
);

