import React, { useContext, FormEvent } from 'react';
import { Card, TextField, Button } from '@material-ui/core';
import { MainLogo } from 'src/components/MainLogo';
import { NavLink, useHistory } from 'react-router-dom';
import { NotLoggedInLayout } from 'src/components/NotLoggedInLayout';
import { ResetPasswordContext } from 'src/model/ResetPasswordContext';

export const PasswordCodePage: React.FC<{}> = () => {
  const resetPasswordModel = useContext(ResetPasswordContext);
  const history = useHistory();
  const codeFromLink = window.location.search.replace('?code=', '') || '';

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const value = e.target['code'].value;
    resetPasswordModel.handleConfirmationCodeInput(value);
    history.push("/passwordenter");
  };

  return (
    <NotLoggedInLayout>
    <Card style={{ padding: '38px' }}>
      <MainLogo />
      <form className='loginForm' noValidate autoComplete='off' onSubmit={handleSubmit}>
        <h3>Password reset</h3>
        <div>Please enter code from email</div>
        <div>
          <TextField fullWidth id='code' label='code' defaultValue={codeFromLink} />
        </div>
        <div className='buttons'>
          <div>
            <Button fullWidth variant='contained' color='primary' type='submit'>
              Next Step
          </Button>
          </div>
          <div>
            <Button fullWidth variant='outlined' color='primary' component={NavLink} exact to='/login' >
              Cancel
          </Button>
          </div>
        </div>
      </form>
    </Card>
    </NotLoggedInLayout>
  );
};
