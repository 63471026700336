import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import './share.css'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
  } from "react-share";

function Share({ show, onClose, qrCodeLink ,fullName}) {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    if (show) {
      setIsBrowser(true);
      document.body.style.overflow = "hidden";
    }
  }, [show]);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose(false);
    document.body.style.overflow = "scroll";
  };

  const modalContent = show ? (
    <div className="share">
        <h1 onClick={(e)=>{handleCloseClick(e)}}>X</h1>
        <div className="shareList">
            <EmailShareButton
                className="shareButton"
                subject='Decenture Blockchain verified Certificates'
                url=''
                body={`Dear []
    I am pleased to inform you that I successfully completed my course with Abu Dhabi STEM challenge. You can verify the authenticity of this certificate by comparing the SHA-256 hash to the list of valid hashes available on the Decenture network via the QR code attached to this link ${qrCodeLink}.
                
    The Decenture Blockchain has authenticated my participation, attendance and successful completion in the course. 
                
Yours sincerely,
                
${fullName}`}
            >
                <EmailIcon/>
            </EmailShareButton>
        
            <FacebookShareButton
                className="shareButton"
                url={qrCodeLink}
                quote='Checkout my Decenture Blockchain certified badge.'
                hashtag="#Decenture"
            >
                <FacebookIcon/>
            </FacebookShareButton>

            <TwitterShareButton
                className="shareButton"
                title='My @Decenture_ #Blockchain verified certificate'
                url={qrCodeLink}
                hashtags={['Decenture_', 'education' ,'blockchain','secured']}
                related= {['Decenture_']}
            >
                <TwitterIcon/>
            </TwitterShareButton>

            <WhatsappShareButton
                className="shareButton"
                title='My Decenture Blockchain certified credential

'
                url={qrCodeLink}
            >
                <WhatsappIcon />
            </WhatsappShareButton>

            <LinkedinShareButton
                className="shareButton"
                url={qrCodeLink}
                title='Decenture Blockchain verified credential'
            >
                <LinkedinIcon/>
            </LinkedinShareButton>
            <TelegramShareButton
                className="shareButton"
                title='My Decenture Blockchain verified credential'
                url={qrCodeLink}
            >
                <TelegramIcon/>
            </TelegramShareButton>
        </div>
    </div>
  ) : null;

  if (isBrowser) {
    const container = document.getElementById("share");
    return container ? ReactDOM.createPortal(modalContent, container) : null;
  }
  return null;
}

export default Share;