import "./mobileTop.css"
import React from 'react';
function MobileTop({fullName,verified}) {
    return (
    <div className="mobile_top">
        <img src="/decenture-logo.png" alt="decemture logo" className="decenture_logo" />
        {verified ? <>
                <p className="title">Certificate Recipient</p>
                <div className="name">
                    <div className="nameCercle">
                        <p className="nameFirstLetter">{fullName.toUpperCase()[0]}</p>
                    </div>
                    <p className="fullName">{fullName}</p>
                </div></>:""}
        
    </div>
  )
}

export default MobileTop;