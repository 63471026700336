import React, { ReactNode } from 'react';
import { AppMenu } from 'src/components/AppMenu';

interface ILayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <div className='layoutLoggedIn'>
      <AppMenu />
      {/* <div className='left'>
      </div> */}
      {/* <div className='main'> */}
        {/* <div className='userMenu'> */}
          {/* <UserMenu /> */}
        {/* </div> */}
        {children}
      {/* </div> */}
    </div>
  );
};
