import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, AppBar, Toolbar, useMediaQuery } from '@material-ui/core';
import { StudentsIcon, GroupsIcon, SubjectsIcon, LessonsIcon, RoomsIcon, TeachersIcon, UploadIcon } from 'src/assets/icons';
import { MainLogo } from 'src/components/MainLogo';
import { AuthContext } from 'src/model/AuthContext';
import { UserMenu } from 'src/components/UserMenu';

const AppMenuLogo: React.FC<{}> = () => {
  const matches = useMediaQuery('(max-width: 800px)');
  let image_url = "/decenture-logo-horizontal.png"
  if (matches)
    image_url = "/decenture-logo-horizontal-mini.png"
  console.log(matches)
  return (
    <div style={{padding: '16px'}} className='logo'>
      <img height={30} src={image_url} alt="decenture" />
    </div>
  );
}

export const AppMenu: React.FC<{}> = () => {
  const role = useContext(AuthContext).user.role;
  const small = useMediaQuery('(max-width: 800px)');

  let buttonStyle = {};
  if (small)
  {
    buttonStyle = {
      width: 50,
    };
}

  return (
    <AppBar position="static" style={{backgroundColor: "white"}}>
      <Toolbar variant="dense">
        <NavLink exact to='/'>
          <AppMenuLogo />
        </NavLink>
        <List>
          <ListItem  style={buttonStyle} button component={NavLink} exact to='/'>
            <ListItemIcon><SubjectsIcon /></ListItemIcon>
            {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>Overview</ListItemText>}
          </ListItem>
        </List>
        <List>
          {(role === 'super-admin' || role === 'teacher') && (
            <ListItem button style={buttonStyle} component={NavLink} exact to='schedule'>
              <ListItemIcon><GroupsIcon /></ListItemIcon>
              {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>Your Schedule</ListItemText>}
            </ListItem>
          )}
          </List>
        <List>
          {(role === 'super-admin' || role === 'admin' || role === 'teacher') && (
            <ListItem button style={buttonStyle} component={NavLink} exact to='students'>
              <ListItemIcon><GroupsIcon /></ListItemIcon>
              {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>Students</ListItemText>}
            </ListItem>
          )}
        </List>
        <List>
          {(role !== 'admin') && (
            <ListItem button style={buttonStyle} component={NavLink} exact to='subjects'>
              <ListItemIcon><LessonsIcon /></ListItemIcon>
              {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>Subjects</ListItemText>}
            </ListItem>
          )}
        </List>
        
        <List>
          {(role === 'super-admin' || role === 'teacher') && (
            <ListItem button style={buttonStyle} component={NavLink} exact to='lessons'>
              <ListItemIcon><StudentsIcon /></ListItemIcon>
              {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>Lessons</ListItemText>}
            </ListItem>
          )}
</List>
<List>
          {(false) && (
            <ListItem button style={buttonStyle} component={NavLink} exact to='teachers'>
              <ListItemIcon><TeachersIcon /></ListItemIcon>
              {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>Teachers</ListItemText>}
            </ListItem>
          )}
</List>
<List>
          {(role === 'super-admin' || role === 'admin') && (
            <ListItem button style={buttonStyle} component={NavLink} exact to='rooms'>
              <ListItemIcon><RoomsIcon /></ListItemIcon>
              {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>Rooms</ListItemText>}
            </ListItem>
          )}
</List>
        <List>
          {(role === 'super-admin') && (
            <ListItem button style={buttonStyle} component={NavLink} exact to='/OneView'>
              <ListItemIcon><RoomsIcon /></ListItemIcon>
              {!small && <ListItemText style={{color: "rgb(40, 77, 130)"}}>OneView</ListItemText>}
            </ListItem>
          )}
          </List>

        <div style={{marginTop: 7,height: 50}} className="userMenu">
          <UserMenu/>
        </div>
      </Toolbar>
    </AppBar>
  );
  // return (
  //   <Drawer variant='permanent' >
  //     <NavLink exact to='/'>
  //       <MainLogo />
  //     </NavLink>
  //     <Divider />
      // <List>
      //   <ListItem button component={NavLink} exact to='/'>
      //     <ListItemIcon><SubjectsIcon /></ListItemIcon>
      //     <ListItemText>Overview</ListItemText>
      //   </ListItem>
      //   {(role === 'super-admin' || role === 'teacher') && (
      //     <ListItem button component={NavLink} exact to='schedule'>
      //       <ListItemIcon><GroupsIcon /></ListItemIcon>
      //       <ListItemText>Your Schedule</ListItemText>
      //     </ListItem>
      //   )}
      // </List>
  //     <Divider />
      // <List>
      //   {(role !== 'admin') && (
      //     <ListItem button component={NavLink} exact to='subjects'>
      //       <ListItemIcon><LessonsIcon /></ListItemIcon>
      //       <ListItemText>Subjects</ListItemText>
      //     </ListItem>
      //   )}
      //   {(role === 'super-admin' || role === 'teacher') && (
      //     <ListItem button component={NavLink} exact to='lessons'>
      //       <ListItemIcon><StudentsIcon /></ListItemIcon>
      //       <ListItemText>Lessons</ListItemText>
      //     </ListItem>
      //   )}
      //   {(role === 'super-admin' || role === 'teacher') && (
      //     <ListItem button component={NavLink} exact to='students'>
      //       <ListItemIcon><GroupsIcon /></ListItemIcon>
      //       <ListItemText>Students</ListItemText>
      //     </ListItem>
      //   )}
      //   {(false) && (
      //     <ListItem button component={NavLink} exact to='teachers'>
      //       <ListItemIcon><TeachersIcon /></ListItemIcon>
      //       <ListItemText>Teachers</ListItemText>
      //     </ListItem>
      //   )}
      //   {(role === 'super-admin' || role === 'admin') && (
      //     <ListItem button component={NavLink} exact to='rooms'>
      //       <ListItemIcon><RoomsIcon /></ListItemIcon>
      //       <ListItemText>Rooms</ListItemText>
      //     </ListItem>
      //   )}
      // </List>
  //     {/* {(role === 'super-admin' || role === 'admin') && (
  //       <>
  //         <Divider />
  //         <List>
  //           <ListItem button component={NavLink} exact to='upload'>
  //             <ListItemIcon><UploadIcon /></ListItemIcon>
  //             <ListItemText>Upload Data</ListItemText>
  //           </ListItem>
  //         </List>
  //       </>
  //     )} */}
  //   </Drawer>
  // );
};
