import storage from 'src/model/storage';
import { CONFIG } from './config';

export const fetchAuth = function (
    url: RequestInfo,
    init?: RequestInit,
    additionalHeaders?: Headers | string[][] | Record<string, string> | undefined
  ) {
    const token = storage.getItem(CONFIG.AUTH_TOKEN_KEY) || '';

    const headers = Object.assign({
      'Authorization': `Bearer ${token}`
    }, additionalHeaders);

    return fetch(url, {
      ...init,
      headers
    });
}
