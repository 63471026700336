import React, { useState, useEffect } from 'react';
import { Layout } from 'src/components/Layout';
import { Upload } from 'src/components/Upload';
import { CONFIG } from 'src/model/config';
import { fetchAuth } from 'src/model/fetchAuth';
import { UploadCertificate } from 'src/components/UploadCertificate';

const apiurl = (point: string): string => CONFIG.API_URL + CONFIG.UPLOAD_URL + '/' + point;

type UploadSource = {
  name: string,
  url: string,
  count: string,
  max: string,
}

export const UploadPage: React.FC<{}> = () => {
  const [uploadSources, setUploadSources] = useState([]);

  const fetchUploadSources = () => {
    fetchAuth(
      apiurl('info')
    ).then(
        res => res.json()
      )
      .then(
        result => setUploadSources(result.uploadSources.map(
          (u: UploadSource) => u = {...u, url: apiurl(u.url)}
        )),
        error => console.error(error)
      )
  }

  useEffect(fetchUploadSources, []);
  
  return (
    <Layout>
      <h1>Upload CSV data</h1>
      { uploadSources.map( (u: UploadSource) => 
        <Upload {...u} key={u.url} onUploadDone={fetchUploadSources} />
      )}

      <h1>Upload PDFs</h1>
      <UploadCertificate url={apiurl('certificate-file')} onUploadDone={() => {}} />
    </Layout>
  );
};
