import React, { ReactNode } from 'react';
import { CONFIG } from 'src/model/config';

interface INotLoggedInLayoutProps {
  children: ReactNode;
}

export const NotLoggedInLayout: React.FC<INotLoggedInLayoutProps> = ({ children }) => {
  return (
    <div className='layoutNotLoggedIn'>
      <div className='background' style={{backgroundImage: `url('${CONFIG.IMAGE_BACKGROUND}')`}}></div>
      <div className='foreground'>
        {children}
      </div>
    </div>
  );
};
