import './pdf.css';
import { Document, Page } from 'react-pdf'
import {AutoSizer} from 'react-virtualized';
import React from 'react';

function PdfPage({verified,pdfFile}) {
 

  return (
    <div className="PdfPage">
        
        { verified?<>
          <div className="logos">
            <img src="/adek-logo.svg" alt="ADEK logo" className="ADEK" />
            <img src="/mubadala_logo.png" alt="Mubadala logo" className="mubadala" />
            {/* <img src="/nyas-logo.png" alt="NYAS logo" className="NYAS" /> */}
        </div>
        <div className="pdfFile">
          <div id = "page" className="pdfcontainer">
            <AutoSizer disableHeight>
            {({width}) => (
              <Document
                      className="thefile"
                      file={pdfFile}
                      options={{ workerSrc: "/pdf.worker.js" }}
                    >
                <Page  pageNumber={1} width={width}/>
              </Document>
              )}
            </AutoSizer>
          </div>
        </div></>:<>
              <img src="warning.png" alt="warning" className="warning" />
              <p className="warningParagraph">this certificate is <span>not verified</span>  by Decenture</p>
        </>
        }
    </div>
  );
}

export default PdfPage