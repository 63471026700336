import React from 'react';
import './sidebar.css';
import QRCode from 'qrcode.react'
import { formatDate } from 'src/util/date-fns';

function Sidebar({verified,fullName,qrCodeLink,issueDate,pdfFile,show,setShow}) {
    return (
    <div className="Sidebar">
        
        <div className={`SidebarContent ${verified? 'verifiyed': 'notVerified'}`}>

            
                <img src="/decenture-logo.png" alt="decemture logo" className="decentureLogo" />
            {verified ? <>
                <p className="title">Certificate Recipient</p>
                
                <div className="name">
                    <div className="nameCercle">
                        <p className="nameFirstLetter">{fullName.toUpperCase()[0]}</p>
                    </div>
                    <p className="fullName">{fullName}</p>
                </div>

                <div className="line"></div>

                <p className="title">Program</p>

                <p className="programName">Abu Dhabi</p>
                <p className="programDiscription">Science Technology Engineering Mathematics Challenge</p>
                
                <div className="line"></div>

                <p className="title">Date of Issue</p>

                <p className="date">{formatDate(issueDate)}</p>

                <div className="verified"> <p>Verified by Decenture </p> <img src="/check.png" alt="verified" className="verIcon" /></div>
                

                <div className="line"></div>

                <div className="buttons">
                    <a href={pdfFile} className="button" download><img src="/download.png" alt="download" /></a>
                    <a onClick={()=>{setShow(true)}} className="button"><img src="/share.png" alt="share" /></a>
                </div>

                <div className="qrcode">
                    <QRCode className="qrImg" value={qrCodeLink} />
                </div> 
            </> : <>
                <div className="buttom">
                    <div className="verified"> <p>Not Verified by Decenture </p> <img src="/notValid.png" alt="verified" className="verIcon" /></div>
                    <div className="line"></div>
                    <div className="qrcode">
                        <QRCode className="qrImg"  value={qrCodeLink} />
                    </div> 
                </div>
            </>}

            
        </div>
    </div>
  );
}

export default Sidebar;
