import React, { FormEvent, useContext } from 'react';
import { Card, TextField, Button } from '@material-ui/core';
import { MainLogo } from 'src/components/MainLogo';
import { NavLink, useHistory } from 'react-router-dom';
import { NotLoggedInLayout } from 'src/components/NotLoggedInLayout';
import { ResetPasswordContext } from 'src/model/ResetPasswordContext';
import { CustomAlert } from 'src/components/CustomAlert';

export const PasswordResetPage: React.FC<{}> = () => {
  const resetPasswordModel = useContext(ResetPasswordContext);
  const history = useHistory();
  const [isErrorOn, setErrorOn] = React.useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const value = e.target['email'].value;
    await resetPasswordModel.handleCreateChangePasswordToken(
      {email: value},
      () => history.push("/passwordenter"),
      () => setErrorOn(true)
    );
  };

  return (
    <NotLoggedInLayout>        
    <Card style={{ padding: '38px' }}>
      <MainLogo />
      <form className='loginForm' noValidate autoComplete='off' onSubmit={handleSubmit}>
        <h3>Password reset</h3>
        <div>Please enter your email address</div>
        <div>
          <TextField fullWidth id='email' label='e-mail' onInput={() => setErrorOn(false)} />
        </div>
        <CustomAlert isOpen={isErrorOn} severity='error' title="Can't proceed" >
          Please check the e-mail address
        </CustomAlert>
        <div className='buttons'>
          <div>
            <Button fullWidth variant='contained' color='primary' type='submit'>
              Next Step
          </Button>
          </div>
          <div>
            <Button fullWidth variant='outlined' color='primary' component={NavLink} exact to='/login' >
              Cancel
          </Button>
          </div>
        </div>
      </form>
    </Card>
    </NotLoggedInLayout>        
  )
};
