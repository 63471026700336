import React, { useContext } from "react";
import { AuthContext } from "src/model/AuthContext";
import { Route, Redirect } from "react-router";

export const PrivateRoute: React.FC<any> = ({ component: Component, role, ...rest }) => {
  const authModel = useContext(AuthContext);
  const roles = role ? role.trim().split('|') : [];

  return (
    <Route
      {...rest}
      render={props =>
        authModel.isAnyOfRoles(roles) ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
};
