import React, { useState } from 'react';
import {DropzoneArea} from 'material-ui-dropzone'
import { Button } from '@material-ui/core';
import { fetchAuth } from 'src/model/fetchAuth';

interface IUploadControlProps {
  name: string,
  url: string,
  count: string,
  max: string,
  schema?: {order: string[], help: string[]},
  onUploadDone?: Function,
}

type UploadFile = Blob;

export const Upload: React.FC<IUploadControlProps> = ({ name, url, count, max, schema, onUploadDone }) => {
  const [files, setFiles] = useState([] as Array<UploadFile>);
  const [filesKey, setFilesKey] = useState(0);
  const [status, setStatus] = useState('Upload');
  const [uploadResult, setUploadResult] = useState('');
  const [uploadError, setUploadError] = useState('');

  const handleChange = (files: Array<UploadFile>) => {
    console.log("CHANGED FILES ARE", files);
    setFiles(files);
  }

  const cleanUp = () => {
    setFiles([]);

    // https://github.com/react-dropzone/react-dropzone/issues/881
    setFilesKey(filesKey+1);
}

  const handleUpload = () => {
    setStatus('Uploading...');
    var formData = new FormData();
    formData.append('file', files[0]);
    fetchAuth(url, {
        method: 'POST',
        body: formData
      })
      .then(
        response => {
          setStatus('Upload');
          cleanUp();
          if (typeof onUploadDone === 'function') {
            onUploadDone(response);
          }
          return response.json();;
        },
        error => {
          console.error(error);
        }
        )
      .then((response) => {
        if (response.parseResult) {
          setUploadResult(`${response.parseResult.passedRows} of ${response.parseResult.totalRows} are ok`);
        } else {
          setUploadResult(`upload failed`);
          setUploadError(response.error);
        }
      })
  }

  return (
    <table className='uploadTable'>
      <tbody>
        <tr>
          <td><h4>{name}</h4></td>
          <td>
            <h5>quantity</h5>
            {count}
          </td>
          <td>
            <h5>last changes</h5>
            {max}
          </td>
          <td className='dropzone single' key={filesKey}>
            <DropzoneArea 
              filesLimit={1}
              dropzoneText='Drag and drop a CSV file or click here'
              onChange={handleChange}
              acceptedFiles={[
                '.csv',
                'text/*',
                'text/csv',
                'application/csv',
                'text/comma-seperated-value',
                'application/comma-seperated-value',
                'text/x-csv',
                'application/x-csv',
                'text/x-comma-seperated-value',
                'application/x-comma-seperated-value',
              ]}
            />
          </td>
          <td>
            <Button variant='contained' color='primary' disabled={!files.length || status !== 'Upload'} onClick={handleUpload}>{status}</Button>
            <small className='uploadResult' title={uploadError}>{uploadResult}</small>
          </td>
          <td className='hint'>
            { schema && (
              <div className='popover'>
                <u>?</u>
                <div className='hint'>
                  <h5>Columns order</h5>
                  <table>
                    <tbody>
                      <tr>
                        {schema.order.map((item, key) => (
                          <th key={key}>{item}</th>
                        ))}
                      </tr>
                      <tr>
                        {schema.help.map((item, key) => (
                          <td key={key}><small>{item}</small></td>
                        ))}
                      </tr>
                      <tr>
                        {schema.order.map((item, key) => (
                          <td key={key}>...</td>
                        ))}
                      </tr>
                      <tr>
                        {schema.order.map((item, key) => (
                          <td key={key}>...</td>
                        ))}
                      </tr>                      
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
