export function formatDate(date: string) {
  const parsedDate = new Date(date);

  return [
    padZero(parsedDate.getDate()),
    padZero(parsedDate.getMonth() + 1),
    padZero(parsedDate.getFullYear()),
  ].join('/');

  function padZero(n: number) {
    return n.toString().padStart(2, '0');
  }
}
