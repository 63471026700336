import React, { useContext } from 'react';
import { Layout } from 'src/components/Layout';
import { DomoEmbed } from 'src/components/DomoEmbed';
import { CONFIG } from 'src/model/config';
import { AuthContext } from 'src/model/AuthContext';

export const OneViewPage: React.FC<{}> = () => {
  const role = useContext(AuthContext).user.role;

  return (
    <Layout>
      
      {role === 'super-admin' && (
        <DomoEmbed url={`${CONFIG.API_URL}/domoEmbed/item/superAminOneView`} />
      )}
    </Layout>
  );
};
